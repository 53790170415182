// extracted by mini-css-extract-plugin
export var careerWorking__btn = "dN_GB";
export var careerWorking__btnWrapper = "dN_Gz";
export var careerWorking__card = "dN_Gp";
export var careerWorking__cardBgDark = "dN_Gr";
export var careerWorking__cardBgLight = "dN_Gq";
export var careerWorking__cardDescription = "dN_Gx";
export var careerWorking__cardHorizontal = "dN_Gs";
export var careerWorking__cardHorizontalBox = "dN_Gt";
export var careerWorking__cardHorizontalImage = "dN_Gv";
export var careerWorking__cardImage = "dN_Gy";
export var careerWorking__cardTitle = "dN_Gw";